import React, { useState } from 'react';
import { validate_grant } from '../utils/apiServices.js';
import { Dialog, Transition } from '@headlessui/react';
import { Pagination, Badge } from '@nextui-org/react';
import SearchHeader from '../components/SearchHeader/SearchHeader';
import Logo from '../components/Logo/Logo';
import Footer from '../components/Footer/Footer';

function GrantValidator() {
  // Add state for the title and body inputs
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  // Add state for showing the spinner and result
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');

  // Define function to handle concatenating the inputs and calling the API
  const handleVerifyProposal = async () => {
    // Check if the fields are filled out
    if (!title || !body) {
      alert('Please fill out both fields.');
      return;
    }

    // Show the spinner
    setIsLoading(true);

    // Create an array with the concatenated title and body
    // Remove pound sign as it removes everything after itself in the API call
    const content = `Title: ${title.replace(/#/g, '')}\n${body.replace(/#/g, '')}`;

    // Call the API to verify the proposal
    const response = await validate_grant(content);

    // Hide the spinner
    setIsLoading(false);

    // Set the result of the API call
    setResult(response?.response);
  };

  return (
    <div className="w-full h-screen">
      <SearchHeader />

      <main
        className="flex flex-col items-center justify-center w-full flex-grow space-y-7"
        style={{ minHeight: 'calc(100vh - 94px)' }}
      >
        <h2 className="mt-8 px-4 text-2xl font-bold leading-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl xl:text-4xl">
          Grant Validator
        </h2>

        {/* Add the explanation and tooltip */}
        <div className="w-full grid grid-cols-1 gap-4 p-4 place-items-center text-left sm:text-lg md:text-lg lg:text-lg xl:text-lg xl:w-1/2 sm:w-1/3" style={{ marginBottom: '100px' }}>
          <p className="text-gray-600 font-medium text-lg mb-1">This grant validator tool ensures that your grant complies with Gitcoin's guidelines.</p>
          <input
            type="text"
            placeholder="Title*"
            className="w-full h-10 px-3 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            title="Please fill out this field"
            required
          />
          <textarea
            placeholder="Body*"
            className="w-full h-32 px-3 py-2 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            title="Please fill out this field"
            required
          ></textarea>

          {/* Add the button to trigger the API call */}
          <button
            className="px-4 py-2 font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-600"
onClick={handleVerifyProposal}
>
Validate Grant
</button>
{/* Show the spinner if loading */}
{isLoading && (
  <div className="flex items-center justify-center">
    <svg
      className="w-6 h-6 mr-3 -ml-1 text-blue-500 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="12"
        stroke="5"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 00-8-8V0c7.627 0 13 5.373 13 12h-4zm-4 4a4 4 0 100-8 4 4 0 000 8z"
      ></path>
    </svg>
    <span>Loading...</span>
  </div>
)}

{/* Show the result of the API call */}
{result && (
  <div className="mt-4 p-4 bg-gray-100 rounded-lg">
    <p className="font-medium text-gray-800">Result:</p>
    <p className="mt-2 text-gray-600 whitespace-pre-wrap">{result}</p>
  </div>
)}
</div>
</main>

<Footer />
</div>
);
}

export default GrantValidator;
