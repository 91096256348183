import React, {Fragment} from 'react';

import Logo from '../Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import {
  BoltIcon,
  CheckBadgeIcon,
  HandThumbUpIcon,
  CheckCircleIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline'

function SearchHeader() {


  const solutions = [
    { name: 'Governance', description: 'Review proposal and discussion summaries', href: '/governance', icon: BoltIcon },
    { name: 'Proposal Validator', description: "Check whether you're following the guidelines", href: '/validateproposal', icon: CheckBadgeIcon },
    { name: 'Grant Validator', description: "Check whether you're following the grant application guidelines", href: '/validategrant', icon: CheckCircleIcon },
  ]


  const callsToAction = [
    { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
  ]


  return (
    <header className="flex items-center content-center w-full px-4 py-2 border border-googray-light">
      <a href="/">
        <Logo className="text-3xl mr-2 sm:mr-8" />
      </a>




      <div className="flex-grow"></div>
      <SearchBar className="w-full sm:w-1/2 mx-4 sm:mx-8" />
    </header>


  );
}

export default SearchHeader;
