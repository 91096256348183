import React from 'react';

function Logo({ className = '' }) {
  return (
    <div className={ 'max-w-[150px] font-vollkorn ' + className }>
      <img src="https://logos-download.com/wp-content/uploads/2022/01/TRON_TRX_Logo_full.png" alt="DAO logo" width="200" height="400"/>
    </div>

  );
}

export default Logo;
